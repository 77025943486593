import 'babel-polyfill';

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
// import * as Sentry from '@sentry/browser';
import App from 'containers/App';

// Apollo imports
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createLink } from 'apollo-absinthe-upload-link';

// WebSocket imports
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';
import { hasSubscription } from '@jumpn/utils-graphql';

import { AlertsProvider } from 'context/AlertsContext';
import { AuthProvider } from 'context/AuthContext';
import { OidcProvider } from 'redux-oidc';
import userManager from 'utils/userManager';
import { getAuthorizationToken } from 'utils/global';
import { getFallbackUrl } from 'requests/auth';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { COLORS } from 'constants/Global';
import 'sanitize.css/sanitize.css';
import 'styles/datepicker.css';
import 'styles/static.scss';
import 'styles/loading-elipssis.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DownloadALertProvider } from 'context/DownloadALertContext';
import { VehiclesProvider } from 'context/VehiclesContext';
import { ParamsOptionsProvider } from 'context/ParamsOptionsContext';
import { RavenProvider } from 'context/RavenContext/RavenContext';
import InternetConnectivityAlert from 'hooks/useNetworkErrorAlert';
import fragmentMatcher from './fragmentMatcher';
import store, { history } from './configureStore';
import AppLocale from './translations';

import '!file-loader?name=[name].[ext]!IMGS_PATH/favicon.png';

// if (isProduction()) {
//   const dsn = getSentryDsn();
//   Sentry.init({
//     dsn,
//     integrations: [new BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }

const MOUNT_NODE = document.getElementById('app');

//TODO: rewrite auth without redux
const authLink = setContext((_, { headers }) => {
  const Authorization = getAuthorizationToken();
  if (Authorization) {
    return { headers: { ...headers, Authorization } };
  }
  return { headers };
});

const wsLink = createAbsintheSocketLink(
  AbsintheSocket.create(
    new PhoenixSocket(`${window.WS_URL}/websocket`, {
      params: () => ({ Authorization: getAuthorizationToken() }),
    })
  )
);

const uploadLink = createLink({
  uri: window.API_URL,
});

const link = split(operation => hasSubscription(operation.query), wsLink, authLink.concat(uploadLink));

const authRequests = ['getLoginAccessToken', 'getCurrentStep', 'checkEmailAndPassword'];

const handleError = ({ graphQLErrors, operation }) => {
  const isAuthRequest = authRequests.includes(operation.operationName);
  const unauthenticated =
    graphQLErrors && graphQLErrors.length && graphQLErrors.find(error => error.code === 'unauthenticated');
  const accessDenied =
    graphQLErrors && graphQLErrors.length && graphQLErrors.find(error => error.code === 'forbidden') && isAuthRequest;

  if (unauthenticated) {
    localStorage.clear();
    sessionStorage.clear();
    userManager.signinRedirect();
  }

  if (accessDenied) {
    localStorage.clear();
    sessionStorage.clear();
    redirect();
  }
};

const client = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  link: ApolloLink.from([onError(handleError), link]),
});

function redirect() {
  client.query({
    query: getFallbackUrl,
    variables: { clientId: window.CLIENT_ID },
  }).then(({ data }) => {
    window.location = data.getFallbackUrl;
  });
}

const theme = createTheme({
  overrides: {
    palette: {
      primary: COLORS.blue,
    },
    MuiInput: {
      underline: {
        '&:before, &:after': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'none',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
  },
});

// TODO: Restore Sentry bug tracking after change SENTRY_DSN const in constants/Global.js
// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     release: process.env.COMMIT,
//     environment: process.env.ENV_TYPE,
//     maxBreadcrumbs: 50,
//   });
// }

const render = () => {
  ReactDOM.render(

    <ApolloProvider client={client}>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <ConnectedRouter history={history}>
            <IntlProvider textComponent={Fragment} messages={AppLocale.en.messages} locale="en">
              <AlertsProvider>
                <AuthProvider>
                  <InternetConnectivityAlert />
                  <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DownloadALertProvider>
                        <VehiclesProvider>
                          <ParamsOptionsProvider>
                            <RavenProvider>
                              <App />
                            </RavenProvider>
                          </ParamsOptionsProvider>
                        </VehiclesProvider>
                      </DownloadALertProvider>
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                </AuthProvider>
              </AlertsProvider>
            </IntlProvider>
          </ConnectedRouter>
        </OidcProvider>
      </Provider>
    </ApolloProvider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')])) // eslint-disable-line prettier/prettier
    .then(() => render())
    .catch(err => {
      throw err;
    });
} else {
  render();
}
