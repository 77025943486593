import { FormattedMessage } from 'react-intl';
import React from 'react';
import { START, STOP } from './Events';

// NotificationAlertType entity

// driving alerts
export const CRASH_ALERT = 'CRASH_ALERT';
export const PANIC_ALERT = 'PANIC_ALERT';
export const HARSH_BRAKING = 'HARSH_BRAKING';
export const HARSH_ACCELERATION = 'HARSH_ACCELERATION';
export const HARSH_TURN = 'HARSH_TURN';
export const PHONE_USAGE = 'PHONE_USAGE';
export const PHONE_CALL = 'PHONE_CALL';
export const BOUNDARY_IN = 'BOUNDARY_IN';
export const BOUNDARY_OUT = 'BOUNDARY_OUT';
export const DRIVE_TIME_LIMIT = 'DRIVE_TIME_LIMIT';
export const MILEAGE_LIMIT = 'MILEAGE_LIMIT';
export const ODOMETER_LIMIT = 'ODOMETER_LIMIT';
export const ENGINE_HOURS_LIMIT = 'ENGINE_HOURS_LIMIT';
export const AUTO_SPEEDING = 'AUTO_SPEEDING';
export const THRESHOLD_SPEEDING = 'THRESHOLD_SPEEDING';
export const IDLING = 'IDLING';
export const UNPLUG = 'UNPLUG';
export const VIN_CHANGE = 'VIN_CHANGE';
export const BUMP = 'BUMP';
export const REFUEL = 'REFUEL';
export const SEATBELT = 'SEATBELT';

//DEVICES
export const DRIVERID = 'DRIVERID';
export const TOOLTAG = 'TOOLTAG';

export const DRIVER_DETECTED = 'driver_detected';
export const BARCODE_TIMEDOUT = 'barcode_timedout';

//health alerts
export const BATTERY_ISSUE = 'BATTERY_ISSUE';
export const LOW_BATTERY_VOLTAGE = 'LOW_BATTERY_VOLTAGE';
export const BATTERY_REMOVED = 'BATTERY_REMOVED';
export const ENGINE_TEMP_ISSUE = 'ENGINE_TEMP_ISSUE';
export const FUEL_LOSS = 'FUEL_LOSS';
export const NEW_DTC = 'NEW_DTC';
export const NEW_RECALL = 'NEW_RECALL';
export const MAINTENANCE_DUE = 'MAINTENANCE_DUE';

// tires issues
export const TIRE_LEAK = 'TIRE_LEAK';
export const TIRE_STATUS_ERROR = 'TIRE_STATUS_ERROR';
export const LOW_TPMS_BATTERY = 'LOW_TPMS_BATTERY';
export const WHEEL_END_SENSOR_ERROR = 'WHEEL_END_SENSOR_ERROR';
export const EXTREME_OVER_PRESSURE = 'EXTREME_OVER_PRESSURE';
export const OVER_PRESSURE = 'OVER_PRESSURE';
export const EXTREME_UNDER_PRESSURE = 'EXTREME_UNDER_PRESSURE';
export const UNDER_PRESSURE = 'UNDER_PRESSURE';
export const CROSS_AXLE_FAULT = 'CROSS_AXLE_FAULT';
export const PRESSURE_SENSOR_ERROR = 'PRESSURE_SENSOR_ERROR';
export const TEMPERATURE_SENSOR_ERROR = 'TEMPERATURE_SENSOR_ERROR';

//TODO: return idling in v 1.1
export const ALERT_TYPES = [
  { label: <FormattedMessage id="app.common.alertTypes.crashAlert" />, value: CRASH_ALERT },
  { label: <FormattedMessage id="app.common.alertTypes.panicAlert" />, value: PANIC_ALERT },
  { label: <FormattedMessage id="app.common.alertTypes.harshBraking" />, value: HARSH_BRAKING },
  { label: <FormattedMessage id="app.common.alertTypes.harshAcceleration" />, value: HARSH_ACCELERATION },
  { label: <FormattedMessage id="app.common.alertTypes.harshTurn" />, value: HARSH_TURN },
  { label: <FormattedMessage id="app.common.alertTypes.speeding" />, value: [AUTO_SPEEDING, THRESHOLD_SPEEDING] },
  { label: <FormattedMessage id="app.common.alertTypes.phoneUsage" />, value: PHONE_USAGE },
  // { label: <FormattedMessage id="app.common.alertTypes.phoneCalls" />, value: PHONE_CALL },
  { label: <FormattedMessage id="app.common.alertTypes.boundary" />, value: [BOUNDARY_IN, BOUNDARY_OUT] },
  { label: <FormattedMessage id="app.common.alertTypes.driveTime" />, value: DRIVE_TIME_LIMIT },
  { label: <FormattedMessage id="app.common.alertTypes.mileage" />, value: MILEAGE_LIMIT },
  { label: <FormattedMessage id="app.common.alertTypes.odometer" />, value: ODOMETER_LIMIT },
  { label: <FormattedMessage id="app.common.alertTypes.engineHours" />, value: ENGINE_HOURS_LIMIT },
  // { label: <FormattedMessage id="app.common.alertTypes.idling" />, value: IDLING },
  { label: <FormattedMessage id="app.common.alertTypes.unplug" />, value: UNPLUG },
  { label: <FormattedMessage id="app.common.alertTypes.vinChange" />, value: VIN_CHANGE },
  { label: <FormattedMessage id="app.common.alertTypes.battery" />, value: BATTERY_ISSUE },
  { label: <FormattedMessage id="app.common.alertTypes.batteryLow" />, value: LOW_BATTERY_VOLTAGE },
  { label: <FormattedMessage id="app.common.alertTypes.batteryRemoved" />, value: BATTERY_REMOVED },
  { label: <FormattedMessage id="app.common.alertTypes.engine" />, value: ENGINE_TEMP_ISSUE },
  { label: <FormattedMessage id="app.common.alertTypes.dtc" />, value: NEW_DTC },
  { label: <FormattedMessage id="app.common.alertTypes.fuelLoss" />, value: FUEL_LOSS },
  {
    label: <FormattedMessage id="app.common.alertTypes.tiresIssue" />,
    value: [
      TIRE_LEAK, TIRE_STATUS_ERROR, LOW_TPMS_BATTERY, WHEEL_END_SENSOR_ERROR, EXTREME_OVER_PRESSURE, OVER_PRESSURE,
      EXTREME_UNDER_PRESSURE, UNDER_PRESSURE, CROSS_AXLE_FAULT, PRESSURE_SENSOR_ERROR, TEMPERATURE_SENSOR_ERROR,
    ],
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.start" />, value: START.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.stop" />, value: STOP.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.bump" />, value: BUMP.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.refuel" />, value: REFUEL.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.seatbelt" />, value: SEATBELT.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.driverid" />, value: DRIVERID.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.tooltag" values={{ value: '' }} />, value: TOOLTAG.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.driverDetected" />, value: DRIVER_DETECTED.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.barcodeTimedout" />, value: BARCODE_TIMEDOUT.toUpperCase(),
  }

];
