import { useEffect } from 'react';
import { useAlert } from 'hooks';

const InternetConnectivityAlert = () => {
  const { openAlert } = useAlert();

  useEffect(() => {
    const handleOnline = () => {
      openAlert('Internet connection restored', 'success');
    };

    const handleOffline = () => {
      openAlert('No internet connection. Please check your network.', 'error');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    if (!navigator.onLine) {
      handleOffline();
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return null;
};

export default InternetConnectivityAlert;
